.mainContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 3rem 1rem;
}

.backgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom left, #ffb400 100%, transparent 0%);
  clip-path: polygon(0 0, 10% 0, 25% 100%, 0% 100%);
}

.dp {
  border-radius: 1rem;
  width: 27rem;
  min-width: 25rem;
  height: 47rem;
  aspect-ratio: 3/5;
  box-shadow: -6px 1px 10px rgba(0, 0, 0, 0.361);
  margin: 2rem;
  z-index: 1;
  overflow: hidden;
}

.motionContainer {
  height: fit-content;
  overflow: hidden;
}

.myImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infoContainer {
  display: flex;
  justify-content: center;
  align-items: self-start;
  flex-direction: column;
  margin-left: 12rem;
}

.banner {
  margin-top: 1rem;
  font-size: 3.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.banner::before {
  content: "-";
  position: absolute;
  left: -3rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 4rem;
  color: #ffb400;
}

.designation {
  font-size: 3.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: var(--body-color);
  margin-top: -1rem;
}

.info {
  width: 25rem;
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.link {
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #8a8a8a;
  border-radius: 50%;
  margin-right: 1.5rem;
  font-size: 1.3rem;
  cursor: pointer;
  background-color: transparent;
  color: #8a8a8a;
}

.link:hover {
  color: #ffb400;
  transition: 0.5s;
}

.btn {
  margin-top: 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  color: #8a8a8a;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 3.2rem;
  width: 100%;
}

.btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-280deg);
  width: 130%;
  height: 130%;
  background-image: conic-gradient(
    #ffb400 0deg,
    #ffb400 90deg,
    transparent 130deg,
    transparent 180deg,
    var(--body-color) 180deg,
    var(--body-color) 270deg,
    transparent 310deg,
    transparent 360deg
  );
  transition: 2s;
}

.btn:hover::before {
  transform: translate(-50%, -50%) rotate(-100deg);
}

.btn::after {
  content: "Contact me";
  font-weight: 600;
  color: var(--body-color);
  position: absolute;
  background-color: var(--body-background);
  border-radius: 5px;
  box-shadow: 0 0 5px var(--body-color);
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 2.8rem;
  width: 98%;
}

.btn:hover::after {
  color: #ffb400;
  transition: 1s;
}

.dark_mode {
  position: fixed;
  right: 10rem;
  top: 6rem;
  z-index: 98;
}

.dark_mode_label {
  width: 65px;
  height: 30px;
  position: fixed;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
  z-index: 99;
}
.dark_mode_label:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 62px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.moon {
  left: 40px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}

@media screen and (max-width: 1024px) {
  .mainContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.5rem 1rem;
  }
  .backgroundContainer {
    display: none;
  }
  .dp {
    margin: 8rem 2.5rem 2.5rem 2.5rem;
    width: 13rem;
    min-width: 13rem;
    height: 13rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
    box-shadow: -6px 1px 10px var(--shadow);
  }
  .designation {
    margin-top: -0.2rem;
  }
  .banner,
  .designation {
    font-size: 1.75rem;
  }
  .myImg {
    height: auto;
    margin-top: -2.5rem;
  }
  .infoContainer {
    margin-left: 0;
    align-items: center;
  }
  .dark_mode {
    top: 1rem;
    right: 4.5rem;
  }
  .dark_mode_label {
    width: 55px;
    height: 26px;
  }
  .dark_mode_label::after {
    height: 20px;
    width: 20px;
  }
  .dark_mode_label svg {
    width: 15px;
  }
  .dark_mode_input:checked + .dark_mode_label:after {
    left: 52px;
  }
  .moon {
    left: 35px;
  }
  .banner::before {
    display: none;
  }
  .info {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    margin-top: 0.8rem;
  }
  .linkContainer {
    margin-top: 2.2rem;
    align-items: center;
    justify-content: space-between;
    width: 11rem;
  }
  .link {
    margin-right: 0;
    height: 2.6rem;
    width: 2.6rem;
    font-size: 1rem;
  }
  .btn {
    margin-top: 1.3rem;
    height: 3rem;
  }
  .btn::after {
    font-size: 1rem;
    height: 2.6rem;
  }
}

@media screen and (max-height: 840px) {
  .dp {
    margin: 2rem 2.5rem;
  }
}
@media screen and (max-height: 700px) {
  .dp {
    margin: 0rem 2.5rem;
  }
}
