body {
  margin: 0;
  box-sizing: border-box;
  background-color: var(--body-background);
  color: var(--body-color-secondary);
  font-weight: 600;
  transition: 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-theme="dark"]{
 --body-background: #0b0b0b;
 --body-color: white;
 --body-color-secondary: #8a8a8a;
 --body-color-primary: #595e62;
 --shadow:  rgba(255, 255, 255, 0.361);
}

[data-theme="light"]{
  --body-background: white;
  --body-color: #191919;
  --body-color-secondary: #5f5f5f;
  --body-color-primary: #adb5bd;
  --shadow:  rgba(0, 0, 0, 0.361);
 }